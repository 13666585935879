import styles from "./dashboard.module.css";
import dummy from "../../images/dummy.png";
import profileLogout from "../../images/profileLogout.png";
import { useState, useEffect } from "react";
import profileNavy from "../../images/profileNavy.png";
import orderNavy from "../../images/orderNavy.png";
import commentNavy from "../../images/commentNavy.png";
import quoteNavy from "../../images/quoteNavy.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserModal from "../../components/user-modal/userModal";
import CommentModal from "../../components/comment-modal/commentModal";
import OrderModal from "../../components/order-modal/orderModal";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import pin from "../../images/location.png";
import { Icon } from "leaflet";

interface IProps {
  userData: any;
  signOut: () => void;
  loading: boolean;
}
export default function Dashboard({ userData, signOut, loading }: IProps) {
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orders, setOrders] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [quotes, setQuotes] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedComment, setSelectedComment] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [admin, setAdmin] = useState();

  const navigate = useNavigate();
  const markerIcon = new Icon({
    iconUrl: pin,
    iconSize: [40, 40],
  });
  const selectUser = () => {
    setStep(1);
  };
  const selectOrder = () => {
    setStep(2);
  };
  const selectComments = () => {
    setStep(3);
  };
  const selectQuotes = () => {
    setStep(4);
  };
  const handleOpenDetail = (detail: any) => {
    setSelectedUser(detail);
    setModalIsOpen(true);
  };
  const handleCommentOpenDetail = (detail: any) => {
    setSelectedComment(detail);
    setCommentModalIsOpen(true);
  };
  const handleOrderOpenDetail = (detail: any) => {
    setSelectedOrder(detail);
    setOrderModalIsOpen(true);
  };
  const closeUserModal = () => {
    setModalIsOpen(false);
  };

  const redirect = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!userData && !loading) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
    if (userData) {
      if (!userData.isAdmin) {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      
    }
  }, [userData, loading, navigate]);

  const getOrders = () => {
    axios
      .get("/api/order")
      .then((res) => {
        setOrders(res.data);
        
      })
      .catch((err) => {
        
      });
  };
  useEffect(() => {
    getOrders();
  }, []);

  const getUsers = () => {
    axios
      .get("/api/user/getAllUsers")
      .then((res) => {
        setUsers(res.data);
        
      })
      .catch((err) => {
        
      });
  };
  useEffect(() => {
    getUsers();
  }, []);
  const getComments = () => {
    axios
      .get("/api/comment")
      .then((res) => {
        setComments(res.data);
        
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    getComments();
  }, []);
  const getQuotes = () => {
    axios
      .get("/api/quote")
      .then((res) => {
        setQuotes(res.data);
        
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    getQuotes();
  }, []);
  
  return (
    <main className={styles.container}>
      {userData && userData?.isAdmin ? (
        <>
          {" "}
          <div className={styles.main}>
            <div className={styles.left}>
              <div className={styles.iconMobDiv}>
                <img
                  onClick={selectUser}
                  className={styles.iconMob}
                  src={profileNavy}
                  alt=""
                />
                <img
                  onClick={selectOrder}
                  className={styles.iconMob}
                  src={orderNavy}
                  alt=""
                />
                <img
                  onClick={selectComments}
                  className={styles.iconMob}
                  src={commentNavy}
                  alt=""
                />
                <img
                  onClick={selectQuotes}
                  className={styles.iconMob}
                  src={quoteNavy}
                  alt=""
                />
              </div>
              <div>
                <div className={styles.profilePicDiv}>
                  <img
                    className={styles.profilePic}
                    src={
                      userData.profileUrl
                        ? "https://api.universetransport.com.au/" +
                          userData.profileUrl
                        : dummy
                    }
                    alt="dummy"
                  />
                  <p className={styles.profileName}>{firstName}</p>
                </div>
                <div onClick={selectUser} className={styles.usersDiv}>
                  <p className={styles.usersText}>Users</p>
                </div>
                <div onClick={selectOrder} className={styles.usersDiv}>
                  <p className={styles.usersText}>Orders</p>
                </div>
                <div onClick={selectComments} className={styles.usersDiv}>
                  <p className={styles.usersText}>Comments</p>
                </div>
                <div onClick={selectQuotes} className={styles.usersDiv}>
                  <p className={styles.usersText}>Quotes</p>
                </div>
              </div>
              <div className={styles.logoutDiv}>
                <img
                  className={styles.logoutIcon}
                  src={profileLogout}
                  alt="logout"
                  onClick={signOut}
                />
              </div>
            </div>
            <div className={styles.right}>
              {step === 1 ? (
                <div className={styles.usersContainer}>
                  {users.map((usersmap, index) => {
                    return (
                      <>
                        <div className={styles.userCardDiv}>
                          <div className={styles.userCardImgDiv}>
                            <img
                              className={styles.userCardImg}
                              src={
                                usersmap.profileUrl
                                  ? "https://api.universetransport.com.au/" +
                                    usersmap.profileUrl
                                  : dummy
                              }
                              alt="dummy"
                            />
                          </div>
                          <div className={styles.userCardDetailDiv}>
                            <p className={styles.userCardDetail}>
                              {usersmap.firstName} {usersmap.lastName}
                            </p>
                            <p className={styles.userCardDetail}>
                              {usersmap.phoneNumber}
                            </p>
                            <p className={styles.userCardDetail}>
                              {usersmap.createdAt
                                ? new Date(
                                    usersmap.createdAt
                                  ).toLocaleDateString()
                                : ""}
                            </p>
                            <button
                              onClick={() => handleOpenDetail(usersmap)}
                              className={styles.userCardBtn}
                            >
                              See More
                            </button>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : step === 2 ? (
                <div className={styles.orderContainer}>
                  {orders.map((ordersMap) => {
                    return (
                      <div className={styles.orderCardDiv}>
                        <div className={styles.orderCardMap}>
                          <MapContainer
                            style={{
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                              zIndex: '99', 
                            }}
                            center={[-37.81062501722524, 144.9899202468286]}
                            zoom={10}
                            scrollWheelZoom={true}
                          >
                            <Marker
                              draggable={false}
                              position={[
                                ordersMap.fromLocation.lat,
                                ordersMap.fromLocation.long,
                              ]}
                              icon={markerIcon}
                            >
                              <Popup>
                                <>
                                  {ordersMap.fromLocation.lat} <br />{" "}
                                  {ordersMap.fromLocation.long}{" "}
                                </>
                              </Popup>
                            </Marker>
                            <Marker
                              draggable={false}
                              position={[
                                ordersMap.toLocation.lat,
                                ordersMap.toLocation.long,
                              ]}
                              icon={markerIcon}
                            >
                              <Popup>
                                <>
                                  {ordersMap.toLocation.lat} <br />{" "}
                                  {ordersMap.toLocation.long}{" "}
                                </>
                              </Popup>
                            </Marker>
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </MapContainer>
                          <div className={styles.orderStatusDiv}>
                            <p className={styles.orderStatusText}>
                              Status: {ordersMap.status}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => handleOrderOpenDetail(ordersMap)}
                          className={styles.orderCardDetailDiv}
                        >
                          <div className={styles.orderCardDetail}>
                            <p className={styles.orderCardDetailText}>Time</p>
                            <p className={styles.orderCardDetailText}>
                              {ordersMap.dateOfService
                                ? new Date(
                                    ordersMap.dateOfService
                                  ).toLocaleString()
                                : ""}
                            </p>
                          </div>
                          <div className={styles.orderCardDetail}>
                            <p className={styles.orderCardDetailText}>
                              {" "}
                              {ordersMap.typeOfWeight ? "Weight" : "Pallete"}
                            </p>
                            <p className={styles.orderCardDetailText}>
                              {ordersMap.typeOfWeight ? (
                                <>{ordersMap.weight} KG</>
                              ) : (
                                <>{ordersMap.pallete} Pallete</>
                              )}
                            </p>
                          </div>
                          <div className={styles.orderCardDetail}>
                            <p className={styles.orderCardDetailText}>Car</p>
                            <p className={styles.orderCardDetailText}>
                              {ordersMap.carId.carName}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : step === 3 ? (
                <div className={styles.commentContainer}>
                  {comments.map((commentsMap) => {
                    return (
                      <>
                      <div
                        onClick={() => handleCommentOpenDetail(commentsMap)}
                        className={styles.commentCardDiv}
                      >
                        <div className={styles.commentCardUserDiv}>
                          <p className={styles.commentCardUserText}>
                            {commentsMap.userId.firstName}{" "}
                            {commentsMap.userId.lastName}
                          </p>
                        </div>
                        <div className={styles.commentCardDetailDiv}>
                          <p className={styles.commentCardDetailText}>
                            {commentsMap.title}
                          </p>
                          <p className={styles.commentCardDetailText}>
                            {commentsMap.text}
                          </p>
                        </div>
                      </div>
                      
                      </>
                    );
                  })}
                </div>
              ) : step === 4 ? (
                <div className={styles.quoteContainer}>
                  {quotes.map((quotesMap) => {
                    return (
                      <div className={styles.quoteCardDiv}>
                        <div className={styles.quoteDetailDiv}>
                          <p className={styles.quoteDetail}>{quotesMap.name}</p>
                          <p className={styles.quoteDetail}>
                            {quotesMap.email}
                          </p>
                        </div>
                        <div className={styles.quoteDetailMessageDiv}>
                          <p className={styles.quoteDetailMessage}>
                            {quotesMap.message}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.backToLogin}>
          <p>You have to login first</p>
          <p>redirecting to Login page</p>
        </div>
      )}
      <UserModal
        detail={selectedUser}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
      <CommentModal
        detail={selectedComment}
        modalIsOpen={commentModalIsOpen}
        setModalIsOpen={setCommentModalIsOpen}
      />
      <OrderModal
        detail={selectedOrder}
        modalIsOpen={orderModalIsOpen}
        setModalIsOpen={setOrderModalIsOpen}
        userData={userData}
      />
    </main>
  );
}
