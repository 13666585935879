import { useCallback, useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/home/home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import axios from "axios";
import { SnackbarProvider } from "notistack";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<any>();
  const getProfile = useCallback(() => {
    setLoading(true);
    axios
      .get("/api/user")
      .then((res) => {
        setUserData(res.data);
        setLoading(false);
        
        
      })
      .catch((err) => {
        
        setLoading(false);
      });
  }, [userData]);

  const signOut = () => {
    axios
      .get("/api/user/signout")
      .then((res) => {
        setUserData(undefined);
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    getProfile();
  }, [getProfile]);
  return (
    <>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={<Home setUserData={setUserData} userData={userData} />}
          />
          <Route
            path="/dashboard"
            element={<Dashboard loading={loading} signOut={signOut} userData={userData} />}
          />

          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}
