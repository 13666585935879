import Modal from "react-modal";
import styles from "./userModal.module.css";
import { useCallback, useEffect, useState } from "react";
import dummy from "../../images/dummy.png";
import callIcon from "../../images/loginCall.png";
import mailIcon from "../../images/mail.png";
import dateIcon from "../../images/dateIcon.png";
import axios from "axios";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import pin from "../../images/location.png";
import { Icon } from "leaflet";

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
  detail: any;
}
export default function UserModal({
  modalIsOpen,
  setModalIsOpen,
  detail,
}: IProps) {
  const markerIcon = new Icon({
    iconUrl: pin,
    iconSize: [40, 40],
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [created, setCreated] = useState<Date>();
  const [userOrder, setUserOrder] = useState<any[]>([]);
  const [userComment, setUserComment] = useState<any[]>([]);
  function closeModal() {
    setModalIsOpen(false);
  }
  
  useEffect(() => {
    if (detail) {
      setUserId(detail._id);
      setFirstName(detail.firstName);
      setLastName(detail.lastName);
      setPhoneNumber(detail.phoneNumber);
      setEmail(detail.email);
      setCreated(detail.createdAt);
    }
  }, [detail]);

  const getUserOrder = useCallback(() => {
    if (detail) {
      axios
        .get(`/api/order/${detail._id}`)
        .then((res) => {
          
          setUserOrder(res.data);
        })
        .catch((err) => {
          
        });
    }
  }, [detail]);

  useEffect(() => {
    getUserOrder();
  }, [getUserOrder]);

  const getUserComment = useCallback(() => {
    if (detail) {
      axios
        .get(`/api/comment/${detail._id}`)
        .then((res) => {
          
          setUserComment(res.data);
        })
        .catch((err) => {
          
        });
    }
  }, [detail]);

  useEffect(() => {
    getUserComment();
  }, [getUserComment]);

  

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.content}
        contentLabel="Example Modal"
        style={{ overlay: { zIndex: 100 } }}
        ariaHideApp={false}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <p className={styles.topText}>
              {firstName} {lastName}
            </p>
          </div>
          <div className={styles.bottom}>
            <div className={styles.userDetail}>
              <div className={styles.userDetailImgDiv}>
                <img className={styles.userDetailImg} src={dummy} alt="dummy" />
              </div>
              <div className={styles.userDetailTextDiv}>
                <img
                  className={styles.userDetailTextImg}
                  src={callIcon}
                  alt=""
                />
                <p className={styles.userDetailText}>{phoneNumber}</p>
              </div>
              <div className={styles.userDetailTextDiv}>
                <img
                  className={styles.userDetailTextImg}
                  src={mailIcon}
                  alt=""
                />
                <p className={styles.userDetailText}>{email}</p>
              </div>
              <div className={styles.userDetailTextDiv}>
                <img
                  className={styles.userDetailTextImg}
                  src={dateIcon}
                  alt=""
                />
                <p className={styles.userDetailText}>
                  {created ? new Date(created).toLocaleDateString() : ""}
                </p>
              </div>
            </div>
            <div className={styles.orderComment}>
              <div className={styles.orders}>
                <div className={styles.orderCardDiv}>
                  {userOrder.map((userOrderMap) => {
                    return (
                      <div className={styles.orderCard}>
                        <div className={styles.orderCardMapDiv}>
                          <MapContainer
                            style={{
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                            }}
                            center={[-37.81062501722524, 144.9899202468286]}
                            zoom={10}
                            scrollWheelZoom={true}
                          >
                            <Marker
                              draggable={false}
                              position={[
                                userOrderMap.fromLocation.lat,
                                userOrderMap.fromLocation.long,
                              ]}
                              icon={markerIcon}
                            >
                              <Popup>
                                <>
                                  {userOrderMap.fromLocation.lat} <br />{" "}
                                  {userOrderMap.fromLocation.long}{" "}
                                </>
                              </Popup>
                            </Marker>
                            <Marker
                              draggable={false}
                              position={[
                                userOrderMap.toLocation.lat,
                                userOrderMap.toLocation.long,
                              ]}
                              icon={markerIcon}
                            >
                              <Popup>
                                <>
                                  {userOrderMap.toLocation.lat} <br />{" "}
                                  {userOrderMap.toLocation.long}{" "}
                                </>
                              </Popup>
                            </Marker>
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </MapContainer>
                        </div>
                        <div className={styles.orderDetailDiv}>
                          <div className={styles.orderDetail}>
                            <p className={styles.orderDetailTextTitle}>Time</p>

                            <p className={styles.orderDetailText}>
                              {userOrderMap.dateOfService
                                ? new Date(
                                    userOrderMap.dateOfService
                                  ).toLocaleString()
                                : ""}
                            </p>
                          </div>
                          <div className={styles.orderDetail}>
                            <p className={styles.orderDetailText}>Weight</p>
                            <p className={styles.orderDetailText}>
                            {userOrderMap.typeOfWeight ? (
                                <>{userOrderMap.weight} KG</>
                              ) : (
                                <>{userOrderMap.pallete} Pallete</>
                              )}
                            </p>
                          </div>
                          <div className={styles.orderDetail}>
                            <p className={styles.orderDetailText}>Car</p>
                            <p className={styles.orderDetailText}>
                              {userOrderMap.carId.carName}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.comments}>
                <div className={styles.commentCardDiv}>
                  {userComment.map((userCommentMap) => {
                    return (
                      <div className={styles.commentCard}>
                        <div className={styles.commentCardTitle}>
                          <p className={styles.commentCardTitleText}>
                            {userCommentMap.title}
                          </p>
                        </div>
                        <div className={styles.commentCardTextDiv}>
                          <p className={styles.commentCardText}>
                            {userCommentMap.text}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
