import Modal from "react-modal";
import styles from "./orderModal.module.css";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import pin from "../../images/location.png";
import { Icon } from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
  detail: any;
  userData: any;
}
interface Location {
  lat: number;
  long: number;
}
export default function OrderModal({
  modalIsOpen,
  setModalIsOpen,
  detail,
  userData,
}: IProps) {
  const markerIcon = new Icon({
    iconUrl: pin,
    iconSize: [40, 40],
  });
  
  function closeModal() {
    setModalIsOpen(false);
  }
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [OrderId, setOrderId] = useState("");
  const [fromLocationLat, setFromLocationLat] = useState<any>(0);
  const [fromLocationLong, setFromLocationLong] = useState<any>(0);
  const [toLocationLat, setToLocationLat] = useState<any>(0);
  const [toLocationLong, setToLocationLong] = useState<any>(0);
  const [dateOfService, setDateOfService] = useState<Date>();
  const [typeOfWeight, setTypeOfWeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [pallete, setPallete] = useState(0);
  const [car, setCar] = useState("");
  const [status, setStatus] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const navigate = useNavigate();
  const [fromLine1, setFromLine1] = useState();
  const [fromLine2, setFromLine2] = useState();
  const [fromPostCode, setFromPostCode] = useState();
  const [fromSubUrb, setFromSubUrb] = useState();
  const [toLine1, setToLine1] = useState();
  const [toLine2, setToLine2] = useState();
  const [toPostCode, setToPostCode] = useState();
  const [toSubUrb, setToSubUrb] = useState();
  useEffect(() => {
    if (detail) {

      setFirstName(detail.userId.firstName);
      setLastName(detail.userId.lastName);
      setOrderId(detail._id);
      
      setFromLocationLat(detail.fromLocation.lat);
      setFromLocationLong(detail.fromLocation.long);
      setToLocationLat(detail.toLocation.lat);
      setToLocationLong(detail.toLocation.long);
      setDateOfService(detail.dateOfService);
      setTypeOfWeight(detail.typeOfWeight);
      setCar(detail.carId.carName);
      setStatus(detail.status);
      setFromLine1(detail.fromAddress.line1);
      setFromLine2(detail.fromAddress.line2);
      setFromPostCode(detail.fromAddress.postCode);
      setFromSubUrb(detail.fromAddress.suburb);
      setToLine1(detail.toAddress.line1);
      setToLine2(detail.toAddress.line2);
      setToPostCode(detail.toAddress.postCode);
      setToSubUrb(detail.toAddress.suburb);
      if (typeOfWeight) {
        setWeight(detail.weight);
      } else setPallete(detail.pallete);
      setPhone(detail.userId.phoneNumber);
      setMail(detail.userId.email);
    }
  }, [OrderId, detail, typeOfWeight]);

  const handleUpdate = (yo: any) => {
    axios
      .post(`/api/order/update/${OrderId}`, {
        status: yo,
      })
      .then((res) => {
        enqueueSnackbar("Order Updated Successfully", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  const statusChanger = (chnagedStatus: string) => {
    setNewStatus(chnagedStatus);
    setStatus(chnagedStatus);
    handleUpdate(chnagedStatus);
  };
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.content}
        contentLabel="Example Modal"
        style={{ overlay: { zIndex: 100 } }}
        ariaHideApp={false}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <p className={styles.topText}>
              {firstName} {lastName}: {OrderId}
            </p>
          </div>
          <div className={styles.bottom}>
            <div className={styles.detailDiv}>
              <div className={styles.mapDiv}>
                <MapContainer
                  style={{
                    width: "100%",
                    height: "100%",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    borderBottomLeftRadius: "20px",
                  }}
                  center={[-37.81062501722524, 144.9899202468286]}
                  zoom={10}
                  scrollWheelZoom={true}
                >
                  <Marker
                    draggable={false}
                    position={[fromLocationLat, fromLocationLong]}
                    icon={markerIcon}
                  >
                    <Popup>
                      <>
                        {fromLocationLat} <br /> {fromLocationLong}{" "}
                      </>
                    </Popup>
                  </Marker>
                  <Marker
                    draggable={false}
                    position={[toLocationLat, toLocationLong]}
                    icon={markerIcon}
                  >
                    <Popup>
                      <>
                        {toLocationLat} <br /> {toLocationLong}{" "}
                      </>
                    </Popup>
                  </Marker>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </MapContainer>
              </div>
              <div className={styles.cordinates}>
                <p className={styles.cordinatesText}>
                  {"Contact Info: "}
                  {phone}
                  {", "}
                  {mail}
                </p>
                <p className={styles.cordinatesText}>
                  {"From:"}
                  {"  "}
                  <a
                    target="blank"
                    href={`https://www.google.com/maps/dir//${fromLocationLat},${fromLocationLong}`}
                  >
                    Open in Google Maps
                  </a>
                </p>
                <p className={styles.cordinatesText}>
                  {"Destination:"}
                  {"  "}
                  <a
                    target="blank"
                    href={`https://www.google.com/maps/dir//${toLocationLat},${toLocationLong}`}
                  >
                    Open in Google Maps
                  </a>
                </p>
                <p className={styles.cordinatesText}>from line1: {fromLine1}</p>
                <p className={styles.cordinatesText}>from line2: {fromLine2}</p>
                <p className={styles.cordinatesText}>
                  from suburb: {fromSubUrb}
                </p>
                <p className={styles.cordinatesText}>
                  from post code: {fromPostCode}
                </p>
                <p className={styles.cordinatesText}>to line1: {toLine1}</p>
                <p className={styles.cordinatesText}>to line2: {toLine2}</p>
                <p className={styles.cordinatesText}>to suburb: {toSubUrb}</p>
                <p className={styles.cordinatesText}>
                  to post code: {toPostCode}
                </p>
                <p className={styles.cordinatesText}>
                  Date Of Service:{" "}
                  {dateOfService
                    ? new Date(dateOfService).toLocaleString()
                    : ""}
                </p>
                <p className={styles.cordinatesText}>
                  {typeOfWeight ? `Weight: ${weight}` : `Pallete: ${pallete}`}
                </p>
                <p className={styles.cordinatesText}>Car: {car}</p>
                <p className={styles.cordinatesText}>Status: {status}</p>
                <div className={styles.btnDiv}>
                  <button
                    onClick={() => statusChanger("Canceled")}
                    className={styles.btn}
                  >
                    Canceled
                  </button>
                  <button
                    onClick={() => statusChanger("Waiting")}
                    className={styles.btn}
                  >
                    Waiting
                  </button>
                  <button
                    onClick={() => statusChanger("In Progress")}
                    className={styles.btn}
                  >
                    In Progress
                  </button>
                  <button
                    onClick={() => statusChanger("Finished")}
                    className={styles.btn}
                  >
                    Finished
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
