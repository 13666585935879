import styles from "./home.module.css";
import universeLogo from "../../images/universeLogo.png";
import loginCall from "../../images/mail.png";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import otpIcon from "../../images/OTP.png";

interface IProps {
  userData: any;
  setUserData: (data: any) => void;
}
export default function AboutUs({ userData, setUserData }: IProps) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [otpMode, setOtpMode] = useState(false);
  const [admin, setAdmin] = useState(true);
  const [login, setLogin] = useState(false);
  const [verify, setVerify] = useState(false);
  useEffect(() => {
    if (userData) {
      if (userData.isAdmin) {
        setAdmin(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1500);
      } else {
        setAdmin(false);
      }
    }
  }, [userData, navigate]);
  const handleLogin = (e: any) => {
    setLogin(true);
    e.preventDefault();
    axios
      .post("/api/user/login/admin", {
        email: email,
      })
      .then((res) => {
        enqueueSnackbar("OTP Sent!", { variant: "success" });
        setOtpMode(true);
        setLogin(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
        setLogin(false);
      });
  };
  const handleOtp = (e: any) => {
    e.preventDefault();
    setVerify(true);
    axios
      .post("/api/user/login/admin/otp", {
        email: email,
        otp: otp,
      })
      .then((res) => {
        setUserData(res.data);
        enqueueSnackbar("successful", { variant: "success" });
        setVerify(false);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1500);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
        setVerify(false);
      });
  };

  const signOut = () => {
    axios
      .get("/api/user/signout")
      .then((res) => {
        enqueueSnackbar("successful", { variant: "success" });
        setTimeout(() => {
          navigate(0);
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };

  return (
    <main className={styles.container}>
      <div className={styles.loginDiv}>
        <div className={styles.logoDiv}>
          <img className={styles.logo} src={universeLogo} alt="logo" />
        </div>
        {!admin ? (
          <div className={styles.notAdmin}>
            <p className={styles.adminPanelText}>
              You can't have access to this page!
            </p>
            <button
              onClick={signOut}
              className={styles.btn}
              id="login-button"
              type="submit"
            >
              Sign Out
            </button>
          </div>
        ) : !otpMode ? (
          <>
            <div className={styles.adminPanelTextDiv}>
              <p className={styles.adminPanelText}>ADMIN PANEL</p>
              <p>Please insert your Email to login</p>
            </div>
            <div className={styles.formDiv}>
              <form className={styles.form}>
                <div className={styles.inputComponents}>
                  <img
                    className={styles.inputIcons}
                    src={loginCall}
                    alt="profileIcon"
                  />
                  <input
                    className={styles.input}
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  disabled={login}
                  onClick={handleLogin}
                  className={styles.btn}
                  id="login-button"
                  type="submit"
                >
                  Log In
                </button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={styles.adminPanelTextDiv}>
              <p className={styles.adminPanelText}>ADMIN PANEL</p>
              <p>Please insert 6-digit OTP Code</p>
            </div>
            <div className={styles.formDiv}>
              <form className={styles.form}>
                <div className={styles.inputComponents}>
                  <img
                    src={otpIcon}
                    className={styles.inputIcons}
                    alt="otpIcon"
                  />
                  <input
                    inputMode="numeric"
                    className={styles.input}
                    placeholder="OTP"
                    type="email"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <button
                  disabled={verify}
                  onClick={handleOtp}
                  className={styles.btn}
                  id="login-button"
                  type="submit"
                >
                  Verify
                </button>
              </form>
            </div>
          </>
        )}
        <p>
          Icons by{" "}
          <a target="blank" href="https://icons8.com/">
            Icon8
          </a>
        </p>
      </div>
    </main>
  );
}
