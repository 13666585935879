import Modal from "react-modal";
import styles from "./commentModal.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
  detail: any;
}
export default function CommentModal({
  modalIsOpen,
  setModalIsOpen,
  detail,
}: IProps) {
  function closeModal() {
    setModalIsOpen(false);
  }
  const [text, setText] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [newEnabled, setNewEnabled] = useState(enabled);
  const [commentId, setCommentId] = useState("");
  useEffect(() => {
    if (detail) {
      setText(detail.text);
      setEnabled(detail.enabled);
      setCommentId(detail._id);
    }
  }, [commentId, detail]);

  const handleUpdate = (data: boolean) => {
    axios
      .post(`/api/comment/update/${commentId}`, {
        enabled: data,
      })
      .then((res) => {
        enqueueSnackbar("Comment Updated Successfully", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  const commentActivation = (active: boolean) => {
    setEnabled((value) => !value);
    handleUpdate(active);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.content}
        contentLabel="Example Modal"
        ariaHideApp={false}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.topText}>
              {detail ? (
                <>
                  {detail.userId.firstName} {detail.userId.lastName}:{" "}
                  {detail.title}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottomTextDiv}>
              <p className={styles.bottomText}>{text}</p>
            </div>
            <div className={styles.btnDiv}>
              {enabled ? (
                <button
                  onClick={() => commentActivation(false)}
                  className={styles.btn}
                >
                  Disable
                </button>
              ) : (
                <button
                  onClick={() => commentActivation(true)}
                  className={styles.btn}
                >
                  Enable
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
